<template>
  <v-card class="ma-0" :maxHeight="height - 24" :minHeight="minHeight">
    <v-card-title class="secondary white--text py-0" @click="fitchData()">
      Требуется проводка бухгалтера ({{ data && data.length }})
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-card-text :style="`height: ${height + ($vuetify.breakpoint.smAndDown ? -14 : -38)}px; overflow: auto`" class="pa-0">
          <a-table-f-data2
            ref="tableReport"
            :dataTable="dataSort"
            :model="model"
            :defaults="{ sort: { key: 'doc_id', order: 'ASC' } }"
            :searchable="false"
            :footerHide="true"
            @click="onClick($event)"
          >
          </a-table-f-data2>
        </v-card-text>
      </div>
      <div v-else>Список пуст</div>
    </v-card-text>
    <s-quickOpenDoc ref="quickOpenDoc" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </v-card>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {},
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      contentHeight: 10,
      showReportDialog: false,
      idReport: 0,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,

      modelConfig: [
        {
          name: "taskToday.work.beforeDay",
          title: "дней до задачи",
          type: "string",
        },
      ],
      model: [
        { name: "parent_id", type: "folder", hidden: true },
        { name: "document", title: "Документ", type: "string", width: 150, isMenu: true, style: "white-space: normal;" },
        { name: "doc_id", title: "docID", type: "string", sortable: true, hidden: true },
        { name: "date_doc", title: "Дата", type: "date", width: 80, sortable: true },
        { name: "status", title: "Действие", type: "string", width: 120, style: "white-space: normal;" },
        { name: "parent_doc_name", title: "оплата для..", type: "string", width: 120, sortable: true, style: "white-space: normal;" },
      ],
    };
  },
  /*
              doc_name: el.account,
            doc_id: el.doc_id,
            doc: "",
            status: "Долг",
            value: el.value_cr - el.value_db,*/
  created() {
    this.fitchData();
  },
  computed: {
    dataSort() {
      return this.data.sort((a, b) => {
        return (a.date_doc < b.date_doc) - (a.date_doc > b.date_doc);
      });
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    dataSort() {
      return this.data.sort((a, b) => {
        return (b.status == 100) - (a.status == 100) || (a.date_start > b.date_start) - (a.date_start < b.date_start);
      });
    },
    filters() {
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(f.work.filters, {
        /*       date_start: {
          condition: "<=",
          value: new Date().date,
        },
 */ date_end: {
          condition: ">=",
          value: new Date().date,
        },
      });
      return res;
    },
  },
  methods: {
    onClick(e) {
      console.log("onclick", e);
      if (e.field?.isMenu) {
        console.log("onclick menu", e);
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      this.idReport = e.row.id;
      this.showReportDialog = true;
    },
    async getUnfinishedDoc() {
      let url = "/accounting/doc/order_withdrawal";
      let params = {
        filters: { status: 1, deleted: 0 },
        wsort1: { key: "date_doc", order: "DESC" },
        sort: { key: "id", order: "desc" },
      };
      let resp = await this.$axios.get(url, { params });
      this.data = [];
      if (resp.data.status == "ok") {
        let d = [];
        resp.data.data.forEach((el) => {
          d.push({
            document: "Расходный ордер № " + el.code_doc,
            doc_id: el.id,
            date_doc: el.date_doc,
            doc: el.name,
            operation_id: el.operation_type,
            base_name: "AccountingDocOrderWithdrawalModel",
            status: "Требуется проводка",
            parent_id: 1,
            parent_doc_name: el.parent_doc_name,
          });
        });
        this.data = d.multiSort(["-doc_id"]);
      }
    },

    async fitchData() {
      this.loading = true;
      this.getUnfinishedDoc();
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
